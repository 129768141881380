import React from 'react';
import styles from '../AboutLanding.module.css'; // Import the CSS file


function About() {
    return (
        <div className={styles.aboutContainer}>
            <section id="about" className={styles.aboutSection}>
                <h2 className={styles.aboutHeader}>About Us</h2>
                <p className={styles.cta}>
                    The program was developed in conjunction with a team of very experienced specialists,
                    generalists and practice advisors who perceived the lack of a COST EFFECTIVE, simple to
                    use communication portal to enhance mutual patient care and optimize their time and that of
                    their teams. By streamlining communication and providing real-time updates, MyPatientStatus
                    ensures that specialists and general dentists are always on the same page regarding patient
                    progress. With easy access to referral details, treatment plans, and patient status, dental
                    professionals can foster stronger collaborations, enhance patient care, and improve overall
                    practice efficiency. Build stronger referral networks and create seamless workflows with
                    MyPatientStatus, the ultimate tool for dental specialists looking to elevate their
                    relationships with general dentists.
                </p>
            </section>
        </div>
    );
}

export default About;